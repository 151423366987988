import React from 'react';
import { LegalFaq } from '../../../types/legal_faq';

interface FaqTabContentProps {
  selectedLegalFaqs: LegalFaq[] | null;
  legalFaqs: LegalFaq[] | null;
}

const FaqTabContent: React.FC<FaqTabContentProps> = ({ selectedLegalFaqs, legalFaqs }) => {
  // データがロード中かチェック
  if (selectedLegalFaqs === null && legalFaqs === null) {
    return <p className="p-4 text-center">データを読み込み中です...</p>;
  }

  // データが0件の場合
  const isSelectedEmpty = selectedLegalFaqs && selectedLegalFaqs.length === 0;
  const isRecommendedEmpty = legalFaqs && legalFaqs.length === 0;

  if (isSelectedEmpty && isRecommendedEmpty) {
    return <p className="p-4 text-center">参照するFAQがありません</p>;
  }

  return (
    <div className="space-y-8">
      {/* ユーザー選択の法務FAQ */}
      {selectedLegalFaqs &&
        selectedLegalFaqs.map((faq) => (
          <div key={`reference-${faq.id}`}>
            <h3 className="border-b border-black-3 pb-2 text-lg font-bold">
              Q{faq.category_number}-{faq.question_number}【{faq.category}】{faq.title}
            </h3>
            <div className="mt-3">
              <span className="rounded bg-darkBlue-1 px-1.5 py-0.5 text-sm">ユーザーの選択</span>
              <h4 className="mt-2 font-bold">質問</h4>
              <p className="mt-1">{faq.question}</p>
              <h4 className="mt-5 font-bold">回答</h4>
              <p className="mt-1">{faq.answer}</p>
            </div>
          </div>
        ))}
      {/* AIによる推薦の法務FAQ */}
      {legalFaqs &&
        legalFaqs.map((faq) => (
          <div key={`legal-${faq.id}`}>
            <h3 className="border-b border-black-3 pb-2 text-lg font-bold">
              Q{faq.category_number}-{faq.question_number}【{faq.category}】{faq.title}
            </h3>
            <div className="mt-3">
              <span className="rounded bg-darkBlue-1 px-1.5 py-0.5 text-sm">AIによる推薦</span>
              <h4 className="mt-2 font-bold">質問</h4>
              <p className="mt-1">{faq.question}</p>
              <h4 className="mt-5 font-bold">回答</h4>
              <p className="mt-1">{faq.answer}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default FaqTabContent;
