import { useState, useEffect, useCallback } from 'react';
import { MenuData, MenuDescription } from '../../../types/menu';
import { snsApiClient, ragApiClient } from '../../../lib/api';
import axios from 'axios';
import { redirectToHostedUI } from '../../../utils/auth';
import { removeDotsAroundEmojis } from '../../../utils/text-processing';
import { LegalFaq } from 'types/legal_faq';
import { Law } from 'types/law';
import { AnswerHistory } from 'types/answer_history';

export const useGenerateForm = (menuData: MenuData | null) => {
  const [formData, setFormData] = useState<Record<string, string | string[]>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [generatedContent, setGeneratedContent] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [selectedLegalFaqs, setSelectedLegalFaqs] = useState<LegalFaq[] | null>(null);
  const [legalFaqs, setLegalFaqs] = useState<LegalFaq[] | null>(null);
  const [laws, setLaws] = useState<Law[] | null>(null);
  const [answerHistories, setAnswerHistories] = useState<AnswerHistory[] | null>(null);
  const [answer, setAnswer] = useState<string>('');
  const [explanation, setExplanation] = useState('');
  const [starCount, setStarCount] = useState<number | null>(null);

  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const initializeFormData = useCallback((menu: MenuData) => {
    const initialFormData: Record<string, string | string[]> = {};
    menu.menu_descriptions.forEach((desc: MenuDescription) => {
      if (desc.default_value !== null) {
        initialFormData[desc.name] = desc.default_value;
      } else if (desc.type === 'multiselect') {
        initialFormData[desc.name] = [];
      } else {
        initialFormData[desc.name] = '';
      }
    });
    setFormData(initialFormData);
  }, []);

  useEffect(() => {
    if (menuData) {
      initializeFormData(menuData);
    }
  }, [menuData, initializeFormData]);

  const validateForm = useCallback(
    (data: Record<string, string | string[]>) => {
      const newErrors: Record<string, string> = {};
      menuData?.menu_descriptions.forEach((desc) => {
        if (desc.is_required && (!data[desc.name] || data[desc.name].length === 0)) {
          newErrors[desc.name] = '必須項目です';
        }
        if (desc.type === 'multiselect' && desc.max_selections !== null) {
          if ((data[desc.name] as string[]).length > desc.max_selections) {
            newErrors[desc.name] = `最大${desc.max_selections}個まで選択できます`;
          }
        }
      });
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    },
    [menuData]
  );

  const handleInputChange = (name: string, value: string | string[]) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleApiError = (error: unknown) => {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        redirectToHostedUI();
      } else {
        setError(error.response?.data?.detail || 'An error occurred');
      }
    } else {
      setError('An unexpected error occurred');
    }
  };

  const handleGenerate = async () => {
    if (!menuData) throw new Error('Menu data is not available');
    setIsGenerating(true);
    setGeneratedContent('');
    setError(null);

    try {
      const requestData = {
        menuId: menuData.id,
        data: formData,
      };

      let path: string;
      // SNS投稿文作成
      if (menuData.name === 'SNS投稿文作成') {
        path = process.env.REACT_APP_SNS_API_URL || '';
      }
      // 法務相談 回答作成
      else if (menuData.name === '法務相談 回答作成') {
        path = process.env.REACT_APP_RAG_API_URL || '';
      } else {
        throw new Error('Invalid menu ID');
      }

      const response = await fetch(`${path}/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error('Response body is not readable');
      }

      const decoder = new TextDecoder();

      let isBannedWordDetected = false;
      let accumulatedContent = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line === 'data: [DONE]') continue;
          if (line.startsWith('data: ')) {
            try {
              const jsonData = JSON.parse(line.slice(6));
              if (jsonData.event) {
                switch (jsonData.event) {
                  case 'banned_words_detected':
                    accumulatedContent = '';
                    setGeneratedContent((prev) => '禁止ワードが検出されたため、再生成を行います。');
                    isBannedWordDetected = true;
                    continue;
                  case 'banned_words_detected_after_regeneration':
                    accumulatedContent = '';
                    setGeneratedContent(
                      (prev) => '再度禁止ワードが検出されました。生成を終了します。'
                    );
                    return; // 生成を終了
                }
              }
              if (jsonData.data) {
                accumulatedContent += jsonData.data;
                const processedContent = removeDotsAroundEmojis(accumulatedContent);
                if (isBannedWordDetected) {
                  isBannedWordDetected = false;
                }
                setGeneratedContent(processedContent);
              } else {
                setGeneratedContent(JSON.stringify(jsonData));
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }
        }
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleEditContentSubmit = async () => {
    setError(null);
    try {
      // SNS投稿文作成
      if (menuData?.name === 'SNS投稿文作成') {
        await snsApiClient.post('/generate/edit', {
          menuId: menuData?.id,
          content: generatedContent,
        });
      }
      // 法務相談 回答作成
      if (menuData?.name === '法務相談 回答作成') {
        await ragApiClient.post('/generate/edit', {
          menuId: menuData?.id,
          content: answer,
          question: question,
          legalFaqs: legalFaqs,
          laws: laws,
        });
      } else {
        throw new Error('Invalid menu ID');
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleCopyContent = async () => {
    setError(null);
    try {
      // SNS投稿文作成
      if (menuData?.name === 'SNS投稿文作成') {
        await snsApiClient.post('/generate/confirm', {
          menuId: menuData?.id,
          content: generatedContent,
        });
        await navigator.clipboard.writeText(generatedContent);
        alert('テキストがコピーされました');
      }
      // 法務相談 回答作成
      else if (menuData?.name === '法務相談 回答作成') {
        await ragApiClient.post('/generate/confirm', {
          menuId: menuData?.id,
          content: answer,
          question: question,
          legalFaqs: legalFaqs,
          laws: laws,
        });
        await navigator.clipboard.writeText(answer);
        alert('テキストがコピーされました');
      } else {
        throw new Error('Invalid menu ID');
      }
    } catch (err) {
      handleApiError(err);
      alert('テキストのコピーに失敗しました');
    }
  };

  const handleResetAndGenerateNew = useCallback(() => {
    if (!menuData) throw new Error('Menu data is not available');
    initializeFormData(menuData);
    setGeneratedContent('');
    setError(null);
  }, [menuData, initializeFormData]);

  return {
    formData,
    setFormData,
    errors,
    generatedContent,
    setGeneratedContent,
    isGenerating,
    error,
    handleInputChange,
    handleGenerate,
    handleEditContentSubmit,
    handleCopyContent,
    handleResetAndGenerateNew,
    validateForm,
    question,
    setQuestion,
    selectedLegalFaqs,
    setSelectedLegalFaqs,
    legalFaqs,
    setLegalFaqs,
    laws,
    setLaws,
    answerHistories,
    setAnswerHistories,
    answer,
    setAnswer,
    explanation,
    setExplanation,
    starCount,
    setStarCount,
  };
};
