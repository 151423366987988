import React, { useRef, useEffect } from 'react';
import Button from '../../../components/common/Button';
import { MenuData } from '../../../types/menu';
import PageTitle from '../../../components/common/PageTitle';
import { LegalFaq } from 'types/legal_faq';
import { Law } from 'types/law';

interface GenerateConfirmPageProps {
  menuData: MenuData;
  menuName: string | undefined;
  generatedContent: string;
  question: string;
  setQuestion: (question: string) => void;
  legalFaqs: LegalFaq[] | null;
  setLegalFaqs: (legalFaqs: LegalFaq[] | null) => void;
  laws: Law[] | null;
  setLaws: (laws: Law[]) => void;
  answer: string;
  setAnswer: (answer: string) => void;
  onNextPost: () => void;
  onCopy: () => void;
  onBack: () => void;
}

const GenerateConfirmPage: React.FC<GenerateConfirmPageProps> = ({
  menuData,
  menuName,
  generatedContent,
  answer,
  setAnswer,
  onNextPost,
  onCopy,
  onBack,
}) => {
  const answerRef = useRef<HTMLTextAreaElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (menuName === 'legal-post') {
      if (answerRef.current) {
        answerRef.current.style.height = 'auto';
        answerRef.current.style.height = `${answerRef.current.scrollHeight}px`;
      }
    } else {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }
  }, [generatedContent, answer, menuName, setAnswer]);

  return (
    <div className="w-[672px] bg-black-1">
      <div className="mb-6 rounded-10 bg-black-0 p-6">
        <PageTitle>{menuData.name}</PageTitle>
        <div className="mb-4">
          <textarea
            ref={menuName === 'legal-post' ? answerRef : textareaRef}
            value={menuName === 'legal-post' ? answer : generatedContent}
            readOnly
            className="w-full resize-none whitespace-pre-wrap rounded-10 border border-black-g bg-black-1 px-3 py-2"
            style={{ minHeight: '200px', overflow: 'hidden' }}
          />
        </div>
        <div className="flex items-center justify-between">
          <Button label="編集に戻る" onClick={onBack} variant="secondary" className="font-bold" />
          <div className="space-x-4">
            <Button label="コピー" onClick={onCopy} className="font-bold" />
            <Button label="次の投稿文を作成" onClick={onNextPost} className="font-bold" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerateConfirmPage;
