import axios from 'axios';

const coreApiClient = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_URL,
  withCredentials: true,
});

const snsApiClient = axios.create({
  baseURL: process.env.REACT_APP_SNS_API_URL,
  withCredentials: true,
});

const ragApiClient = axios.create({
  baseURL: process.env.REACT_APP_RAG_API_URL,
  withCredentials: true,
});

export { coreApiClient, snsApiClient, ragApiClient };
