import React, { useState } from 'react';
import { LegalFaq } from '../../../types/legal_faq';
import { Law } from '../../../types/law';
import { AnswerHistory } from 'types/answer_history';
import FaqTabContent from './FaqTabContent';
import LawTabContent from './LawTabContent';
import SimilarTabContent from './SimilarTabContent';
import { ReferenceTab } from '../../../types/reference_tab';

interface ReferencePanelProps {
  selectedLegalFaqs: LegalFaq[] | null;
  legalFaqs: LegalFaq[] | null;
  laws: Law[] | null;
  answerHistories: AnswerHistory[] | null;
}

const ReferencePanel: React.FC<ReferencePanelProps> = ({
  selectedLegalFaqs,
  legalFaqs,
  laws,
  answerHistories,
}) => {
  const [activeTab, setActiveTab] = useState<ReferenceTab>('faq');

  // タブの切り替えハンドラー
  const handleTabChange = (tab: ReferenceTab) => {
    setActiveTab(tab);
  };

  // タブのクラスを動的に生成する関数
  const getTabClasses = (tab: ReferenceTab) => {
    return `h-10 w-1/3 cursor-pointer flex items-center justify-center rounded-t-md border ${
      activeTab === tab
        ? 'bg-white text-black-g border-black-g border-b-0'
        : 'bg-darkBlue-3 text-white border-black-g'
    }`;
  };

  // アクティブなタブに応じてコンテンツを表示
  const renderContent = () => {
    switch (activeTab) {
      case 'faq':
        return <FaqTabContent selectedLegalFaqs={selectedLegalFaqs} legalFaqs={legalFaqs} />;
      case 'law':
        return <LawTabContent laws={laws} />;
      case 'similar':
        return <SimilarTabContent answerHistories={answerHistories} />;
      default:
        return null;
    }
  };

  return (
    <div className="mb-6 w-[400px] rounded-10 bg-black-0 p-6">
      <div className="mb-6 w-[352px] rounded-md border text-black-g">
        <div className="flex">
          <div className={getTabClasses('faq')} onClick={() => handleTabChange('faq')}>
            参照法務FAQ
          </div>
          <div className={getTabClasses('law')} onClick={() => handleTabChange('law')}>
            参照法令
          </div>
          <div className={getTabClasses('similar')} onClick={() => handleTabChange('similar')}>
            類似過去回答
          </div>
        </div>
        <div className="rounded-b-md border border-t-0 border-black-g bg-white p-4">
          <div className="min-h-[400px] overflow-y-auto">{renderContent()}</div>
        </div>
      </div>
    </div>
  );
};

export default ReferencePanel;
