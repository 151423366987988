import React from 'react';
import { AnswerHistory } from '../../../types/answer_history';

interface SimilarTabContentProps {
  answerHistories: AnswerHistory[] | null;
}

const SimilarTabContent: React.FC<SimilarTabContentProps> = ({ answerHistories }) => {
  console.log(answerHistories);
  // データがロード中かチェック
  if (answerHistories === null) {
    return <p className="p-4 text-center">データを読み込み中です...</p>;
  }

  // データが0件の場合
  if (answerHistories.length === 0) {
    return <p className="p-4 text-center">類似する過去回答がありません</p>;
  }

  return (
    <div className="space-y-4">
      {answerHistories.map((answerHistory) => (
        <div key={answerHistory.id}>
          <div className="rounded border border-black-g p-4">
            <div className="flex items-baseline justify-between border-b border-black-3 pb-1">
              <h4 className="font-bold">質問</h4>
              <span className="text-10 font-normal text-black-g">
                回答日時：{answerHistory.created_at} 担当者：{answerHistory.family_name}
              </span>
            </div>
            <div className="mt-2 gap-2 whitespace-pre-line text-sm">{answerHistory.question}</div>
            <h4 className="mt-4 border-b border-black-3 pb-1 font-bold">回答</h4>
            <div className="mt-2 whitespace-pre-line">{answerHistory.answer}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SimilarTabContent;
