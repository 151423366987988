import React from 'react';
import { Law } from '../../../types/law';

interface LawTabContentProps {
  laws: Law[] | null;
}

const LawTabContent: React.FC<LawTabContentProps> = ({ laws }) => {
  // データがロード中かチェック
  if (laws === null) {
    return <p className="p-4 text-center">データを読み込み中です...</p>;
  }

  // データが0件の場合
  if (laws.length === 0) {
    return <p className="p-4 text-center">参照する法令がありません</p>;
  }

  return (
    <div className="space-y-8">
      {laws.map((law) => (
        <div key={law.id}>
          <h3 className="border-b border-black-3 pb-2 text-lg font-bold">
            {law.name} {law.article}
          </h3>
          <p className="mt-3">{law.article_content}</p>
        </div>
      ))}
    </div>
  );
};

export default LawTabContent;
