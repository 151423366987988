import React, { useEffect, useRef } from 'react';
import Button from '../../../components/common/Button';
import TextArea from '../../../components/common/TextArea';
import { MenuData } from '../../../types/menu';
import PageTitle from '../../../components/common/PageTitle';
import ReferencePanel from './ReferencePanel';
import { LegalFaq } from 'types/legal_faq';
import { Law } from 'types/law';
import { AnswerHistory } from 'types/answer_history';

interface GenerateEditPageProps {
  menuData: MenuData;
  menuName: string | undefined;
  isGenerating: boolean;
  onSubmit: () => void;
  onBack: () => void;

  generatedContent: string;
  setGeneratedContent: (content: string) => void;

  question: string;
  setQuestion: (question: string) => void;

  selectedLegalFaqs: LegalFaq[] | null;
  setSelectedLegalFaqs: (selectedLegalFaqs: LegalFaq[]) => void;

  legalFaqs: LegalFaq[] | null;
  setLegalFaqs: (legalFaqs: LegalFaq[] | null) => void;

  laws: Law[] | null;
  setLaws: (laws: Law[]) => void;

  answerHistories: AnswerHistory[] | null;
  setAnswerHistories: (laws: AnswerHistory[]) => void;

  answer: string;
  setAnswer: (answer: string) => void;

  explanation: string;
  setExplanation: (explanation: string) => void;

  starCount: number | null;
  setStarCount: (starCount: number | null) => void;
}

const GenerateEditPage: React.FC<GenerateEditPageProps> = ({
  menuData,
  menuName,
  generatedContent,
  setGeneratedContent,
  isGenerating,
  onSubmit,
  onBack,
  question,
  setQuestion,
  selectedLegalFaqs,
  setSelectedLegalFaqs,
  legalFaqs,
  setLegalFaqs,
  laws,
  setLaws,
  answerHistories,
  setAnswerHistories,
  answer,
  setAnswer,
  explanation,
  setExplanation,
  starCount,
  setStarCount,
}) => {
  const questionRef = useRef<HTMLTextAreaElement>(null);
  const answerRef = useRef<HTMLTextAreaElement>(null);
  const referenceRagRef = useRef<HTMLTextAreaElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (menuName === 'legal-post') {
      if (generatedContent && generatedContent.trim() !== '') {
        try {
          const parsedContent = JSON.parse(generatedContent);
          switch (parsedContent.type) {
            case 'question':
              setQuestion(parsedContent.question || '');
              break;
            case 'reference':
              setSelectedLegalFaqs(parsedContent.selected_legal_faqs);
              setLegalFaqs(parsedContent.legal_faqs_results);
              setLaws(parsedContent.laws_results);
              setAnswerHistories(parsedContent.similarity_answers);
              break;
            case 'answer':
              setAnswer(parsedContent.answer || '');
              break;
            case 'explanation':
              setExplanation(parsedContent.explanation || '');
              break;
          }
        } catch (error) {
          console.error('Failed to parse content:', error);
          setQuestion('');
          setAnswer('');
        }
      } else {
        setQuestion('');
        setAnswer('');
      }
    } else {
      setGeneratedContent(generatedContent);
    }
  }, [
    generatedContent,
    setGeneratedContent,
    menuName,
    answer,
    setAnswer,
    setExplanation,
    setLegalFaqs,
    setLaws,
    setAnswerHistories,
    setQuestion,
    setSelectedLegalFaqs,
  ]);

  useEffect(() => {
    if (menuName === 'legal-post') {
      if (questionRef.current && answerRef.current) {
        questionRef.current.style.height = `${questionRef.current.scrollHeight + 2}px`;
        answerRef.current.style.height = `${answerRef.current.scrollHeight + 2}px`;
        if (referenceRagRef.current) {
          referenceRagRef.current.style.height = `${referenceRagRef.current.scrollHeight + 2}px`;
        }
      }
    } else {
      if (textareaRef.current) {
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`;
      }
    }
  }, [generatedContent, question, answer, selectedLegalFaqs, menuName]);

  const handleAnswerChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    if (menuName === 'legal-post') {
      const trimmedContent = newContent.replace(/^回答\n\n/, '');
      setAnswer(trimmedContent);
      setGeneratedContent(JSON.stringify({ question, answer: trimmedContent, reference_rag: [] }));
    } else {
      setGeneratedContent(newContent);
    }
  };

  useEffect(() => {
    if (explanation) {
      const match = explanation.match(/星:\s*(\d+)/);
      if (match && match[1]) {
        setStarCount(parseInt(match[1], 10));
      }
    } else {
      setStarCount(null);
    }
  }, [explanation, setStarCount]);

  return (
    <div className="bg-black-1">
      <div className="flex">
        <div className="mb-6 w-[672px] rounded-10 bg-black-0 p-6">
          <PageTitle>{menuData.name}</PageTitle>
          <div className="mb-4 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="mr-2"
            >
              <path
                d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
                fill="#FFFF00"
                stroke="#000000"
              />
              <line x1="12" y1="9" x2="12" y2="13" stroke="#000000" />
              <line x1="12" y1="17" x2="12.01" y2="17" stroke="#000000" />
            </svg>
            <p>生成された文章は必ずチェックした上でご利用ください</p>
          </div>
          <div className="relative mb-4">
            {menuName === 'legal-post' && (
              <div className="mb-4">
                <div className="absolute left-3 top-1 text-lg font-bold">質問</div>
                <textarea
                  ref={questionRef}
                  value={`${question}`}
                  readOnly
                  className="w-full resize-none whitespace-pre-wrap rounded-10 border border-black-g bg-black-1 px-3 pb-2 pt-16"
                  style={{ minHeight: '100px', overflow: 'hidden' }}
                />
              </div>
            )}
            <div className="relative mb-4">
              <div className="absolute left-3 top-1 text-lg font-bold">回答</div>
              <TextArea
                label=""
                value={menuName === 'legal-post' ? `${answer}` : generatedContent}
                onChange={handleAnswerChange}
                placeholder={isGenerating ? '生成中...' : '編集してください...'}
                className="h-40 w-full whitespace-pre-wrap rounded-10 border border-black-g px-3 pb-7 pt-16"
                disabled={isGenerating}
                ref={menuName === 'legal-post' ? answerRef : textareaRef}
              />
              <div className="absolute bottom-3 right-3">
                {menuName === 'legal-post' ? answer.length : generatedContent.length}
              </div>
            </div>
            {menuName === 'legal-post' && (
              <div className="mb-4">
                <div className="w-full rounded-10 border border-darkBlue-3 bg-darkBlue-1 p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="mr-2 rounded-full bg-darkBlue-3 p-1 text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="size-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <h3 className="text-lg font-bold">解説</h3>
                    </div>
                    {starCount !== null && (
                      <div className="flex rounded-full bg-darkBlue-3 px-3 py-1">
                        {[1, 2, 3].map((star) => (
                          <svg
                            key={star}
                            xmlns="http://www.w3.org/2000/svg"
                            className="size-6 text-yellow-300"
                            viewBox="0 0 20 20"
                            fill={star <= starCount ? 'currentColor' : 'none'}
                            stroke={star <= starCount ? 'none' : 'currentColor'}
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="mt-7">{explanation.replace(/星:\s*\d+/g, '').trim()}</div>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-between">
            <Button
              label="戻る"
              onClick={onBack}
              variant="secondary"
              disabled={isGenerating}
              className="font-bold"
            />
            <Button
              label="確認画面へ"
              onClick={onSubmit}
              disabled={isGenerating}
              className="font-bold"
            />
          </div>
        </div>
        <div className="ml-4">
          <ReferencePanel
            selectedLegalFaqs={selectedLegalFaqs}
            legalFaqs={legalFaqs}
            laws={laws}
            answerHistories={answerHistories}
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateEditPage;
